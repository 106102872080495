'use strict';

Gri.module({
  name: 'carousel-epsilon',
  ieVersion: null,
  $el: $('.carousel-epsilon'),
  container: '.carousel-epsilon',
  fn: function () {

    const $this = this.$el;
    $this.owlCarousel({
      items: 1,
      nav: false,
      dots: true,
      lazyLoad: true
    });

  }
});
